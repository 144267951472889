"use client";
import type { HomePageConfig } from "@/cms/schema";
import { useEffect, useRef } from "react";

const SMALL_MAX_SIZE = 1000;
let isLarge = window.innerWidth > SMALL_MAX_SIZE;

export default function BackgroundVideo({
  video_landscape,
  video_landscape_small,
  video_portrait,
  video_portrait_small,
}: HomePageConfig) {
  const ref = useRef<HTMLVideoElement>(null);

  const updateVideo = async () => {
    if (ref.current) {
      try {
        ref.current.load();
        await ref.current.play();
      } catch (error) {
        // Uncommon: Window resized before it can play the initial loaded video.
        // Does not impact UX as the video load requests will be canceled
      }
    }
  };

  const onResize = async () => {
    if (window.innerWidth > SMALL_MAX_SIZE != isLarge) {
      isLarge = window.innerWidth > SMALL_MAX_SIZE;
      await updateVideo();
    }
  };

  useEffect(() => {
    window.addEventListener("orientationchange", updateVideo);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("orientationchange", updateVideo);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div>
      <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black" />
      <video
        autoPlay
        playsInline
        loop
        muted
        ref={ref}
        className="absolute inset-0 -z-20 h-full w-full object-cover"
      >
        {/* LANDSCAPE */}
        <source
          src={`https://cms.cdtm.com/assets/${video_landscape.id}/${video_landscape.filename_download}`}
          type="video/mp4"
          media={`screen and (orientation: landscape) and (min-width: ${SMALL_MAX_SIZE + 1}px)`}
        />
        <source
          src={`https://cms.cdtm.com/assets/${video_landscape_small.id}/${video_landscape_small.filename_download}`}
          type="video/webm"
          media={`screen and (orientation: landscape) and (max-width: ${SMALL_MAX_SIZE}px)`}
        />
        {/* PORTRAIT */}
        <source
          src={`https://cms.cdtm.com/assets/${video_portrait.id}/${video_portrait.filename_download}`}
          type="video/mp4"
          media={`screen and (orientation: portrait) and (min-width: ${SMALL_MAX_SIZE + 1}px)`}
        />
        <source
          src={`https://cms.cdtm.com/assets/${video_portrait_small.id}/${video_portrait_small.filename_download}`}
          type="video/webm"
          media={`screen and (orientation: portrait) and (max-width: ${SMALL_MAX_SIZE}px)`}
        />
      </video>
    </div>
  );
}
